import Cookies from "js-cookie";
import { formatCurrency as format } from "@coingecko/cryptoformat";

const locale = document.body.getAttribute("data-locale");
const _MILLION_THRESHOLD = 1000000; // 1 million

// Retrieves the active currency for the current page, which can be page-dependent.
export function getActiveCurrency() {
  const currencyOverride = document.body.getAttribute("data-currency-override");
  let currencyCode = "usd";

  if (currencyOverride) {
    currencyCode = currencyOverride.toLowerCase();
  } else {
    // for path /locale/global_charts, only usd is supported
    if (window.location.href.includes("global_charts")) {
      currencyCode = "usd"
    } else {
      currencyCode = Cookies.get("selected_iso") || "usd";
    }
  }
  return currencyCode;
}

export function setActiveCurrency(currencyCode) {
  if (currencyCode) {
    Cookies.set("selected_iso", currencyCode, { expires: 365, path: "/" });
  }
}

// Symbols map cache
let symbolsMap;

export function getSymbolForCurrency(currencyCode) {
  if (symbolsMap == null) {
    symbolsMap = JSON.parse(document.body.getAttribute("data-currency-symbols"));
  }
  return symbolsMap[currencyCode] || "$";
}

export function formatCurrency(value, isoCode, raw = false, noDecimal = false, abbreviated = false) {
  const formattedCurrency = format(value, isoCode, locale, raw, noDecimal, abbreviated);
  return _refineCurrencyFormat(formattedCurrency, value, locale, isoCode);
}

// displayRawNumber handles the case of very large numbers
// e.g. 1e21 is shown as 10000000000...
// e.g. 1e-21 is shown as 0.0000000...1
export function displayRawNumber(value) {
  const match = value.toString().match(/(.*)e(.*)/);
  // "1e2".match(/(.*)e(.*)/) => ["1e2", "1", "2", index: 0, input: "1e2", groups: undefined]
  if (match && match[1] && match[2]) {
    const exponential = Number(match[2]);

    let res = "";
    if (exponential > 0) {
      for (let i = 0; i < exponential; i++) {
        res += "0";
      }
      return match[1] + res;
    } else if (exponential < 0) {
      for (let i = 0; i < Math.abs(exponential) - 1; i++) {
        res += "0";
      }
      return "0." + res + match[1].replace(".", "");
    }
  }

  return value.toString();
}

function _refineCurrencyFormat(formattedCurrency, value, locale, isoCode) {
  // to handle the missing spacing in ES currency
  // 1,235 BUS$ => 1,235 B US$
  // 12,345 mil MUS$ => 12,345 mil M US$
  // 1234,56 MUS$ => 1234,56 M US$
  if (locale === "es" && isoCode === "usd" && value >= _MILLION_THRESHOLD && /[MB]/.test(formattedCurrency)) {
    const lastThreeChars = formattedCurrency.slice(-3);
    return formattedCurrency.slice(0, -3) + " " + lastThreeChars;
  }

  return formattedCurrency;
}
