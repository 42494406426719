import { formatCurrency, getActiveCurrency } from "../../util/currency";
import { buildChartDefs, drawWatermark } from "./generic_chart";

const _1_HOUR_IN_MS = 1 * 60 * 60 * 1000;
const _1_THOUSAND = 1000;

const EXPONENTS_CONFIG = {
  maximumDecimalTrailingZeroes: 1,
  significantFigures: 4,
};

const formatterDateTime = new Intl.DateTimeFormat(I18n.locale, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hourCycle: 'h23',
  timeZoneName: 'short',
});

export const buildCategoryChartAxes = (formatter) => {
  return {
    title: {
      text: null
    },
    showLastLabel: true,
    opposite: true,
    labels: {
      align: "left",
      reserveSpace: true,
      useHTML: true,
      formatter: formatter
    },
  };
}

export const buildCategoryChartSeries = (coinsData, seriesType) => {
  const seriesDataArray = coinsData.map((coinData, index) => {
    return {
      name: coinData.name,
      type: seriesType,
      data: coinData.data,
      colorIndex: index % 7,
      threshold: -Infinity,
      maxPointWidth: 250,
      boostThreshold: undefined,
      dataGrouping: { enabled: false },
      lineWidth: 1,
      marker: {
        enabled: false,
        radius: 0
      },
      states: {
        hover: {
          lineWidth: 1
        }
      },
    };
  });

  return seriesDataArray;
};

export const buildCategoryChartConfig = (data, element, chartType, currencyCode = getActiveCurrency()) => {
  let seriesType;
  let formatter;

  if (chartType === "dominance") {
    seriesType = "area";
    formatter = percentageFormatter();
  } else {
    seriesType = "line";
    formatter = currencyFormatter(currencyCode);
  }

  return {
    legend: { enabled: true },
    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 0.5,
      formatter() {
        let content = `<div class="title">${formatterDateTime.format(this.x)}</div> `;

        // Loop through all y-axes and display in tooltip.
        for (let point of this.points) {
          let name = point.series.name;
          let value;

          if (chartType === "dominance") {
            value = `${point.y.toFixed(2)}%`;
          } else {
            const currency = currencyCode;
            value = formatCurrency(point.y, currency)
          }

          content += `<div>${name}: <span class="value">${value}</span></div>`;
        }

        return content;
      }
    },

    xAxis: {
      minRange: _1_HOUR_IN_MS,
    },
    yAxis: buildCategoryChartAxes(formatter),
    series: buildCategoryChartSeries(data, seriesType),

    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },

    plotOptions: {
      area: {
        stacking: "percent",
        lineWidth: 2,
        fillOpacity: 0.05,
        marker: {
          enabled: false
        }
      },
      series: { animation: false }
    },

    chart: {
      events: { render: (e) => drawWatermark(e, true) },
      panning: false,
      marginTop: 50,
      renderTo: element.id,
      styledMode: true,
      zoomType: 'x',
    },

    time: { useUTC: false },

    ...buildChartDefs(),
  };
};

export const currencyFormatter = (currencyCode) => {
  return function() {
    return formatCurrency(this.value, currencyCode, false, this.value < 1 ? EXPONENTS_CONFIG : false, this.value >= _1_THOUSAND);
  };
}

export const percentageFormatter = () => {
  return function() {
    return `${this.value.toFixed(2)}%`;
  };
}
