import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Application } from "stimulus";
import editorialContentController from "../../../controllers_v2/editorial_content_controller";
import highlightsController from "../../../controllers_v2/highlights_controller";
import coinTableController from "../../../controllers_v2/coin_table_controller";

const application = Application.start();
const context = require.context("controllers_v2/categories", true, /.js$/);

application.load(definitionsFromContext(context));
application.register("editorial-content", editorialContentController);
application.register("highlights", highlightsController);
application.register("coin-table", coinTableController);
