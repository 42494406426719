import { Controller } from "stimulus";
import { chipSelect } from "../../util/gecko_primer";

export default class extends Controller {
  static targets = [
    "marketCapMin", "marketCapMax", "marketCapChip",
    "volume24hMin", "volume24hMax", "volume24hChip",
    "change24hMin", "change24hMax", "change24hChip",
    "assetPlatformId",
  ];

  handleFilter(e) {
    const url = new URL(e.currentTarget.dataset.url);

    if (this.marketCapMinTarget.value) {
      url.searchParams.set("mcap_min", this.marketCapMinTarget.value);
    }

    if (this.marketCapMaxTarget.value) {
      url.searchParams.set("mcap_max", this.marketCapMaxTarget.value);
    }

    if (this.volume24hMinTarget.value) {
      url.searchParams.set("volume_24h_min", this.volume24hMinTarget.value);
    }

    if (this.volume24hMaxTarget.value) {
      url.searchParams.set("volume_24h_max", this.volume24hMaxTarget.value);
    }

    if (this.change24hMinTarget.value) {
      url.searchParams.set("change_24h_min", this.change24hMinTarget.value);
    }

    if (this.change24hMaxTarget.value) {
      url.searchParams.set("change_24h_max", this.change24hMaxTarget.value);
    }

    if (this.assetPlatformIdTarget.value !== "all") {
      url.searchParams.set("asset_platform_id", this.assetPlatformIdTarget.value);
    }

    window.location.href = url.toString();
  }

  resetFilter(e) {
    chipSelect(this.marketCapChipTarget, "all");
    this.marketCapMinTarget.value = null;
    this.marketCapMaxTarget.value = null

    chipSelect(this.volume24hChipTarget, "all");
    this.volume24hMinTarget.value = null;
    this.volume24hMaxTarget.value = null;

    chipSelect(this.change24hChipTarget, "all");
    this.change24hMinTarget.value = null;
    this.change24hMaxTarget.value = null;

    this.assetPlatformIdTarget.innerText = "All Chains";
    this.assetPlatformIdTarget.value = "all";
  }
}
